// @import "~antd/dist/antd.css";
// @import "./antdstyles.scss";

html {
  --secondary: rgb(57, 141, 207);
  --secondary-bright: rgb(105, 169, 219);
  --primary: rgb(207, 123, 57);
  --primary-bright: hwb(26 41% 14%);
  --warning: rgb(138, 46, 46);
  --white: rgb(221, 221, 221);
  --grey: rgb(185, 185, 185);
}
html[data-theme="dark"] {
  --secondary: rgb(207, 121, 56); // #cf7b39
  --secondary-bright: hwb(26 41% 14%);
  --primary: rgb(57, 141, 207); //#398dcf
  --primary-bright: rgb(105, 169, 219);
  --warning: rgb(138, 46, 46);
  --white: rgb(52, 52, 52);
  --grey: rgb(185, 185, 185);
}
body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.no_select {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.allow_select
{
  -webkit-touch-callout:text; /* iOS Safari */
  -webkit-user-select:text;   /* Chrome/Safari/Opera */
  -khtml-user-select:text;    /* Konqueror */
  -moz-user-select:text;      /* Firefox */
  -ms-user-select:text;       /* Internet Explorer/Edge */
  user-select:text;           /* Non-prefixed version */
}

.allow_select_one_click
{
  -webkit-touch-callout:all; /* iOS Safari */
  -webkit-user-select:all;   /* Chrome/Safari/Opera */
  -khtml-user-select:all;    /* Konqueror */
  -moz-user-select:all;      /* Firefox */
  -ms-user-select:all;       /* Internet Explorer/Edge */
  user-select:all;           /* Non-prefixed version */
}

.ant-collapse-content-box {
  padding: none !important;
}
