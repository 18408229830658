.recipe_index__row {
  font-size: x-large;
}
.left_recipe__row {
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  // margin-left: auto;
  margin-right: auto;
  // width: 80%;
  height: 6vh;
}
.right_recipe__row {
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  // margin-right: auto;
  // width: 80%;
  height: 6vh;
}

.polygon {
  -webkit-clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
  clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
}

.underline__row {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88%;
  }
}