.menu_item__row__brand {
  height: 34vh;
}
.menu_item__row_action {
  margin: 3.5rem;
}
.menu_item__col {
  justify-content: center;
  margin: auto;
  border: 2px solid;
  border-image-slice: 1;
  border-radius: 10px !important;
}
.grounded-radiants::after {
  position: absolute;
  top: -4px; bottom: -4px;
  left: -4px; right: -4px;
  background: linear-gradient(red, blue);
  content: '';
  z-index: -1;
  border-radius: 16px;
}
.menu_item__row_action:active {}
.menu_item__col_action {
  height: 50%;
  // border-radius: 10px;
}
.menu_item__col_action:active {}
.menu_item__heading_action:active {}
.menu_item__heading_action {
  padding: 1.5rem;
}
.menu_item__heading {
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  color: white;
}
.menu_settings__row {}
.menu_settings__col {
  width: 80%;
}
.menu_settings__col_action {}
.rotate{
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate.forward{
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate.back{
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.menu_settings__bottom_left {
  font-size: 40px;
  position: fixed;
  bottom: 3vh;
  left: 2vh;
  z-index: 200;
}
.menu_settings__bottom_right {
  font-size: 40px;
  position: fixed;
  bottom: 3vh;
  right: 2vh;
  z-index: 200;
}