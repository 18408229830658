// @import "./antdstyles.scss";

.highlight_action__anim {
  animation-name: kf_background_color;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  // color: white !important;
  // border-color: $white;
}

@keyframes kf_background_color {
  0% {
    // border-color: $primary;
    // color: $primary;
  }
  25% {
    // border-color: $primary;
    // color: $primary;
  }
  50% {
    // border-color: $primary;
    // color: $primary;
  }
  75% {
    // border-color: $primary;
    // color: $primary;
  }
  100% {
    // border-color: $primary;
    // color: $primary;
  }
}
