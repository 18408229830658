// @import "./antdstyles.scss";
@import "./Animators.module.scss";
@import "./Layout.module.scss";
@import "./Text.module.scss";
@import "../components/Ingredient/Ingredient.module.scss";
@import "../components/Menu/Menu.module.scss";
@import "../components/Recipes/Recipes.module.scss";
@import "../components/ShoppingLists/ShoppingLists.module.scss";

// desktop

.trunk {
  display: block;
  width: 100%;
  margin: auto;
}

.public_highlight_button {
  // border-color: $primary;
  // color: $primary;
}

@media (max-width: 768px) {
  .trunk {
    height: 100vh !important;
  }
  .adding {
    // background-color: #FF5230 !important;
    // box-shadow: #FF5230;
  }
  .floating_button {
    // border: 1px solid black;
  }
  .highlight_action__static_inverted {
    // border-color: $primary;
    background-color: white;
    // color: $primary;
  }
  .spacer__row {
    height: 10vh;
    width: 100vw;
  }
  .action_switch {
    width: 60%;
  }
  .action_button {
    font-style: bold;
    display: "";
    border-radius: 8px !important;
  }
  .action_button:active {
    color: white !important;
  }
  .action_button__warning {
    font-style: bold;
    // background-color: $warning;
    color: white;
  }
  .icon_button {
    font-size: 30px;
  }
  .action_link {
    color: black !important;
  }
  .brand__row {
    padding: 8px;
  }
  .brand_hero__row {
    height: 25vh;
  }
  // .brand__row:active {
  //   background-color: white;
  // }
  .color_palette__col {
    width: 100%;
  }
  .color_palette__row {
    margin-top: 10px;
    height: 50px !important;
    width: 100%;
  }
  .color_palette__row_selected {
  }
  .divider {
    margin: auto;
    font-size: 1rem;
  }
  .breadcrumb_text {
  }
  .footer__row {
    height: 10vh;
    width: 100vw;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 99;
    background-color: white;
  }
  .font__large {
    font-size: large;
  }
  .font__small {
    font-size: small;
  }
  .form {
    margin: 8px;
  }
  .form_input_textarea {
    border-radius: 8px !important;
    font-size: large;
  }
  .form_input {
    border-radius: 8px !important;
    height: 3rem;
    font-size: large;
  }
  .search_input {
    border-radius: 8px !important;
    height: 2.5rem;
    font-size: large;
  }
  .modal_container {
    padding: 2rem;
  }
  .modal_input_label_text__hide {
    color: rgba(0, 0, 0, 0.0);
    transform: translateY(1rem);
    transition: all 0.3s ease-out;
  }
  .modal_input_label_text__show {
    transition: all 0.3s ease-out;
    transform: translateY(-0.05rem);
  }
  .action_item__row {
    margin-left: auto;
    margin-right: auto;
  }
  .action_item__col {
    justify-content: center;
    margin: auto;
  }
  .account_heading {
    color: black;
    padding-left: 8px;
  }
  .brand__heading {
    padding-left: 8px;
  }
  .brand__heading:active {
    // color: $primary;
  }
  .item_heading__col {
    padding-left: 8px;
  }
  .trunk__overlay {
    width: 100vw;
    height: 100vh;
    z-index: 500;
    background-color: rgba(black, 0.1) !important;
  }
  .item_heading__row {
    padding: 4px;
  }
  .item__row_highlighted {

  }
  .item__row_bagged {
    font-style: italic;
    text-decoration: line-through;
  }
  .ant-tabs-tab-active {
    border-bottom: 2px solid #FF5230 !important;
  }
  .page_header {
    margin: auto;
    text-align: center;
  }
  .input__col {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: auto;
    word-wrap: break-word;
  }
  .modal_input__col {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: auto;
    word-wrap: break-word;
  }
  .input__row {
    width: 100vw;
    height: 10vh;
    font-size: large;
  }
  .modal_input_textarea__row {
    width: 100%;
    height: 100%;
  }
  .modal_input__row {
    width: 100%;
    height: 4rem;
  }
  .item__col {
    justify-content: center;
    margin: auto;
  }
  .item__row {
    padding: 8px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 6vh;
  }
  .item_text__lg {
    font-size: small !important;
    word-wrap: break-word;
    word-break: keep-all;
  }
  .item_text__sm {
    font-size: x-small !important;
    word-wrap: break-word;
    word-break: keep-all;
  }
  .item_text__xl {
    font-size: x-large !important;
    word-wrap: break-word;
    word-break: keep-all;
  }
  .amount_text__lg {
    font-size: small;
    word-wrap: break-word;
    word-break: keep-all;
  }
  .amount_text__xl {
    font-size: large;
    word-wrap: break-word;
    word-break: keep-all;
  }
  .item__row_odd {
    width: 100%;
  }
  .item__row_odd:active {
    background-color: white;
  }
  .item__row_context_delete {
    // background-color: $warning;
  }
  .nav_up_down__left {
    position: fixed;
    bottom: 9vh;
    left: 0;
    z-index: 200;
  }
  .nav_up_down__right {
    position: fixed;
    bottom: 9vh;
    right: 0;
    z-index: 200;
  }
  .nav_add__left {
    border-radius: 50%;
    width: 100% !important;
    position: fixed;
    bottom: 2vh;
    left: 0;
    z-index: 200;
  }
  .nav_add__right {
    width: 100% !important;
    position: fixed;
    bottom: 2vh;
    right: 0;
    z-index: 200;
  }
  .nav_profile__bottom_right {
    font-size: 40px;
    position: fixed;
    bottom: 3vh;
    right: 0;
    z-index: 200;
  }
  .more {
    margin: auto;
    width: 100%;
  }
  .step_text {
    color: white !important;
    padding: none;
  }
  .options {
    // z-index: 200 !important;
    // width: 7vw;
    position: fixed;
    // right: 5%;
    // top: 7%;
  }
  .options_item {
    border-radius: 50%;
    font-size: 25px;
    padding: 8px;
  }
  .options_1__show {
    transition: all 0.18s ease-out;
    transform: translateX(-0.5rem);
  }
  .options_1__hide {
    transform: translateX(8rem);
    transition: all 0.18s ease-out;
  }
  .options_2__show {
    transition: all 0.3s ease-out;
    transform: translateX(-0.5rem);
  }
  .options_2__hide {
    transform: translateX(8rem);
    transition: all 0.3s ease-out;
  }
  .sidebar {
    height: 100%;
  }
  .site__heading {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    color: white !important;
  }
  .collapse_parent {
  }
  .sidebar__header {
  }
  .sidebar__heading {
    color: white;
  }
  .sidebar__content {
    z-index: 100;
  }
  .sidebar__content_panel {
    padding: none !important;
  }
  .sidebar__content_panel_body {
    padding: none !important;
  }
  .sidebar__content_heading {
    color: black;
  }
  .sign_in__row {
    padding: 8px;
  }
  .sign_in__input {
    height: 100%;
    width: 100%;
  }
  .ribbon_badge {
    font-size: "1.3rem";
  }
  .ribbon_badge_public {
    font-size: "0.5rem";
    // background-color: $primary !important;
  }
  .submit_button {
    width: 100%;
  }
  .tab_parent {
  }
  .tab_pane {
  }
  .warning_button {
    // border-color: 1px solid $warning !important;
    background-color: white;
    // color: $warning !important;
  }
  .warning_button:active {
    // border-color: 1px solid $warning !important;
    // background-color: $warning !important;
    color: white !important;
  }
  .item__row__blank {
    background-color: white;
    height: 34vh;
  }
  .item_row__blank_heading {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
  }
  .site__primary_color {
    // color: $primary;
  }
}

@media (min-width: 768px) {
  .trunk {
    width: 60vw;
    height: 100% !important;
  }
  .spacer__row {
    height: 10vh;
    width: 100vw;
  }
  .action_button {
    font-style: bold;
    display: "";
    margin-bottom: 15px;
    border-radius: 8px !important;
  }
  .action_button:active {
    color: white !important;
  }
  .action_button__warning {
    font-style: bold;
    // background-color: $warning;
    color: white;
  }
  .icon_button {
    font-size: 30px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .action_link {
    color: black !important;
  }
  .brand__row {
    left: 0;
    height: 7vh;
  }
  .brand__row:active {
    background-color: white;
  }
  .color_palette__col {
    width: 100%;
  }
  .color_palette__row {
    margin-top: 10px;
    height: 4vh !important;
    width: 100%;
  }
  .color_palette__row_selected {
  }
  .divider {
    margin: auto;
  }
  .footer__row {
    height: 10vh;
    width: 100vw;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 99;
    background-color: white;
  }
  .font__large {
    font-size: large;
  }
  .font__small {
    font-size: small;
  }
  .form {
    margin: 8px;
  }
  .brand_hero__row {
    height: 25vh;
  }
  .menu_item__row_action {
    margin: 8px;
    height: 25vh;
  }
  .menu_item__row_action:active {
  }
  .menu_item__col_action {
    height: 50%;
    border-radius: 10px;
  }
  .menu_item__col_action:active {
  }
  .menu_item__heading {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    color: white;
  }
  .menu_item__heading_action {
    padding: 1.5rem;
  }
  .menu_item__heading_action:active {
  }
  .form_input {
    margin-bottom: 2rem !important;
    border-radius: 8px !important;
    height: 3rem;
    font-size: large;
  }
  .modal_container {
    padding: 2rem;
    width: 100%;
    height: 100%;
  }
  .modal_input_label_text__hide {
    color: rgba(0, 0, 0, 0.0);
    transform: translateY(1rem);
    transition: all 0.3s ease-out;
  }
  .modal_input_label_text__show {
    transition: all 0.3s ease-out;
    transform: translateY(-0.05rem);
  }
  .action_item__row {
    padding: 4px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .action_item__col {
    justify-content: center;
    margin: auto;
  }
  .account_heading {
    color: black;
    padding-left: 8px;
  }
  .brand__heading {
    padding-left: 8px;
  }
  .brand__heading:active {
    // color: $primary;
  }
  .item_heading__col {
    padding-left: 8px;
  }
  .trunk__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    background-color: rgba(black, 0.5) !important;
  }
  .item_heading__row {
    height: 7vh;
    padding-left: 8px;
  }
  .item__row_highlighted {
  }
  .item__row_bagged {
    font-style: italic;
    text-decoration: line-through;
  }
  .ant-tabs-tab-active {
    border-bottom: 2px solid #FF5230 !important;
  }
  .modal_parent {
    border-radius: 25px !important;
  }
  .page_header {
    padding: 2px;
  }
  .input__col {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: auto;
    word-wrap: break-word;
  }
  .modal_input__col {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: auto;
    word-wrap: break-word;
  }
  .input__row {
    width: 100vw;
    height: 10vh;
    font-size: large;
  }
  .modal_input__row {
    width: 100%;
  }
  .ant-modal-body {
    width: 50vh !important;
  }
  .item__col {
    justify-content: center;
    margin: auto;
  }
  .item__row {
    padding: 8px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .item_text__lg {
    font-size: large;
    word-wrap: break-word;
    word-break: break-all;
  }
  .item_text__sm {
    font-size: small;
    word-wrap: break-word;
    word-break: break-all;
  }
  .item_text__xl {
    font-size: x-large;
    word-wrap: break-word;
    word-break: break-all;
  }
  .amount_text__lg {
    font-size: small;
    word-wrap: break-word;
    word-break: break-all;
  }
  .amount_text__xl {
    font-size: large;
    word-wrap: normal;
    word-break: break-all;
  }
  .item__row_odd {
    width: 100%;
  }
  .item__row_odd:active {
    background-color: white;
  }
  .item__row_context_delete {
    // background-color: $warning;
  }
  .nav_up_down__left {
    position: fixed;
    bottom: 9vh;
    left: 0;
    z-index: 200;
  }
  .nav_up_down__right {
    position: fixed;
    bottom: 9vh;
    right: 0;
    z-index: 200;
  }
  .nav_add__left {
    border-radius: 50%;
    width: 100% !important;
    position: fixed;
    bottom: 2vh;
    left: 0;
    z-index: 200;
  }
  .nav_add__right {
    width: 100% !important;
    position: fixed;
    bottom: 2vh;
    right: 0;
    z-index: 200;
  }
  .nav_profile__bottom_right {
    font-size: 40px;
    position: fixed;
    bottom: 3vh;
    right: 0;
    z-index: 200;
  }
  .options {}
  .options_item {
    border-radius: 50%;
    font-size: 24px;
    padding: 8px;
  }
  .options_1__show {
  }
  .options_1__hide {
  }
  .options_2__show {
  }
  .options_2__hide {
  }
  .rotate{
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .rotate.forward{
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .rotate.back{
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .sidebar {
    height: 100%;
  }
  .sidebar__header {}
  .sidebar_heading__row {}
  .sidebar_heading__col {}
  .sidebar__heading {
    color: white;
  }
  .sidebar__content {
    z-index: 100;
  }
  .sidebar__content_panel {}
  .sidebar__content_panel_body {}
  .sidebar__content_heading {
    color: black;
  }
  .sign_in__row {
    height: 10vh;
    margin-top: 1.5vh;
    width: 100%;
  }
  .sign_in__input {
    height: 100%;
    width: 100%;
  }
  .site__primary_color {
    // color: $primary;
  }
  .ribbon_badge {}
  .submit_button {
    width: 100%;
  }
  .tab_pane_heading {
  }
  .tab_parent {
  }
  .tab_pane {
  }
  .warning_button {
    // border-color: 1px solid $warning !important;
    background-color: white;
    // color: $warning !important;
  }
  .warning_button:active {
    // border-color: 1px solid $warning !important;
    // background-color: $warning !important;
    color: white !important;
  }
  .item__row__blank {
    background-color: white;
    height: 34vh;
  }
  .item_row__blank_heading {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
  }
  .site__heading {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    font-size: 40px;
    color: white !important;
  }
  .step_text {
    color: "white";
    padding: "none";
  }
}
